/* RESPONSIBLE TEAM: team-phone */
/* eslint-disable @intercom/intercom/no-bare-strings */

export type FinVoiceVoiceOptions = {
  value: FinVoiceVoice;
  text: string;
};

export type FinVoiceVoice =
  | 'alloy'
  | 'shimmer'
  | 'echo'
  | 'ash'
  | 'ballad'
  | 'coral'
  | 'sage'
  | 'verse';

export const FIN_VOICE_VOICE_OPTIONS: FinVoiceVoiceOptions[] = [
  { value: 'alloy', text: 'Alloy' },
  { value: 'shimmer', text: 'Shimmer' },
  { value: 'echo', text: 'Echo' },
  { value: 'ash', text: 'Ash' },
  { value: 'ballad', text: 'Ballad' },
  { value: 'coral', text: 'Coral' },
  { value: 'sage', text: 'Sage' },
  { value: 'verse', text: 'Verse' },
];
