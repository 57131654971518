/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import EditModeConfig from 'embercom/objects/content-editor/edit-mode-configuration';
import { StandardAudiencePanelConfiguration } from 'embercom/objects/content-editor/panels/standard-panel-configurations';
import StandardAudienceEditorConfiguration from 'embercom/objects/audience-editor/standard-audience-editor-configuration';

export default function generateEditModeConfig(options) {
  let { container } = options;
  let app = container.lookup('service:appService')?.app;
  let attributesService = container.lookup('service:attributeService');
  let additionalAttributes =
    app?.canUseStandalone && attributesService?.standaloneZendeskBrandAttribute
      ? attributesService.standaloneZendeskBrandAttribute
      : undefined;

  let audienceEditorConfiguration = new StandardAudienceEditorConfiguration({
    showMatchBehavior: false,
    showAudiencePreview: false,
    showClientPredicates: false,
    showEventRules: false,
    allowedUserRoles: ['visitor', 'user', 'lead'],
    useRolePredicateGroup: true,
    additionalAttributes,
  });

  return EditModeConfig.create({
    headerComponentConfigs: [],
    editorPanelConfigs: [
      StandardAudiencePanelConfiguration.create({
        audienceEditorConfiguration,
        container,
      }),
    ],
    footerComponentConfigs: [],
  });
}
